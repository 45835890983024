import { Box, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import TicketWindow from "./TicketWindow";
import Live from "./Live";
import { doc, onSnapshot, query } from "firebase/firestore";
import { useFirebase } from "@/libs/hooks/firebase";
import useAPI from "@/libs/hooks/api";

const Stream = ({
  id,
  creator,
  streamUrl,
  ticket,
  coverImage,
  plans,
  onRefresh,
  startedAt,
}) => {
  const api = useAPI();
  const toast = useToast();
  const { firestore } = useFirebase();
  const [status, setStatus] = useState("OFFLINE");
  const [viewers, setViewers] = useState(0);
  const [income, setIncome] = useState(0);

  const onPurchase = useCallback(
    async (type) => {
      await api.buyStreamTicket(id, type);
      toast({
        title: "購買成功",
        status: "success",
      });
      await onRefresh(id);
    },
    [api, id, onRefresh, toast],
  );

  // listen to stream status snapshots
  useEffect(() => {
    if (!id) return;
    const q = query(doc(firestore, `stream-status/${id}`));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      if (!snapshot.exists) return;
      const { income, viewersTotal, status } = snapshot.data();
      if (income) setIncome(income);
      if (viewersTotal) setViewers(viewersTotal);
      setStatus(status);
    });
    return () => unsubscribe();
  }, [firestore, id]);

  return (
    <Box
      position="relative"
      height="100%"
      maxW={{ base: "none", md: 500 }}
      mx="auto"
      py={{ base: 0, md: 5 }}
    >
      {ticket && status === "LIVE" ? (
        <Live
          id={id}
          creator={creator}
          viewers={viewers}
          income={income}
          streamUrl={streamUrl}
        />
      ) : (
        <TicketWindow
          coverImage={coverImage}
          tickets={plans}
          ticket={ticket}
          startedAt={startedAt}
          onPurchase={onPurchase}
        />
      )}
    </Box>
  );
};

export default Stream;
